import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, Button, Card, Spinner } from "react-bootstrap";
import classes from "./Login.module.css";
import {
  loginConfirmUser,
  resendLoginVerificationEmail,
} from "../../../redux/actions/authActions";
import { browserName, deviceType, isMobileOnly } from "react-device-detect";
import logo from "../../../assets/images/iQuoto-Global-Logo-Blue.svg";
import { FiAlertTriangle } from "react-icons/fi";

const Step = props => {
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);
  const [code, setCode] = useState(new Array(6).fill(""));
  const submitButtonRef = useRef(null);

  useEffect(() => {
    setErrors({});
    if (localStorage.userEmail && localStorage.userPassword) {
      setUserData({
        email: localStorage.userEmail,
        password: localStorage.userPassword,
      });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(props.errors).length > 0) {
      setErrors(props.errors);
    }

    setDisableLoginBtn(false);
  }, [props]);

  useEffect(() => {
    if (isCodeFullyEntered()) {
      submitButtonRef.current.click();
    }
  }, [code]);

  const isCodeFullyEntered = () => {
    return code.every(value => value !== "");
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return; // Ensure only numbers are entered

    setCode([...code.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on next input if value is not empty
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handlePaste = e => {
    // Get pasted data
    const pasteData = e.clipboardData.getData("text");

    // Check if pasted data is 6 characters long
    if (pasteData.length === 6) {
      // Prevent the default paste action
      e.preventDefault();

      // Split the pasted data into individual characters
      const characters = pasteData.split("");

      // Create a new array by replacing the values in the `code` array with the pasted characters
      const updatedCode = [...code]; // Copy the current code array

      characters.forEach((char, idx) => {
        if (idx < updatedCode.length) {
          updatedCode[idx] = char;
        }
      });

      // Now, update the state with the new code values at once
      setCode(updatedCode); // Assuming `setCode` is your state setter
    }
  };

  const handleSubmit = e => {
    e.preventDefault();

    const emailCode = code.join("");
    if (emailCode.length < 6) return false;

    const userPostData = {
      email: userData.email,
      password: userData.password,
      code: emailCode,
      deviceType: deviceType === "browser" ? "desktop" : deviceType,
      browserName,
      // socialId: userData.socialId
    };

    setDisableLoginBtn(true);
    props.loginConfirmUser(userPostData);
  };

  const handleResendEmail = () => {
    if (window.confirm("Press OK to send the verification email again.")) {
      props.resendLoginVerificationEmail(userData.email);
    }
  };

  return (
    <div
      className="h-100 d-flex align-items-center justify-content-center"
      style={
        !isMobileOnly ? { backgroundColor: "var(--color-light-gray5)" } : null
      }
    >
      <Card className={[classes.card, "px-md-5"].join(" ")}>
        <Card.Body>
          <object
            data={logo}
            width="135"
            height="51"
            className={classes.loginLogo}
            onClick={() => (window.location.href = "https://iquoto.global/")}
            aria-label="iQuoto.com"
          />

          <p>
            We sent a verification email to{" "}
            <strong>{userData.email || "email"}</strong>. Enter the 6-digit
            verification code we sent to proceed to the next step!
          </p>

          <Form onSubmit={handleSubmit}>
            {/*<Form>*/}
            <Form.Row className="d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                {code.map((data, index) => {
                  return (
                    <input
                      type="text"
                      name="code"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={e => handleChange(e.target, index)}
                      onFocus={e => e.target.select()}
                      onPaste={e => handlePaste(e)}
                      className="form-control m-1 py-4 text-center"
                      style={{ width: "50px" }}
                    />
                  );
                })}
              </div>
            </Form.Row>

            <Form.Text className="text-danger text-left pb-4">
              {errors.server || errors.emailorpassword || errors.message ? (
                <FiAlertTriangle className={classes.warningIcon} />
              ) : null}
              {errors.server}
              {errors.emailorpassword}
              {errors.message}
            </Form.Text>

            <Button
              type="submit"
              className="blueButton mt-0 mb-4 w-100"
              // onClick={handleSubmit}
              disabled={disableLoginBtn}
              ref={submitButtonRef}
            >
              {disableLoginBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  VERIFY CODE
                </>
              ) : (
                "VERIFY CODE"
              )}
            </Button>
          </Form>

          <Button
            variant="link"
            className={classes.emailResend}
            onClick={() => handleResendEmail()}
          >
            Email did not arrive?
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

Step.propTypes = {
  auth: PropTypes.object.isRequired,
  loginConfirmUser: PropTypes.func.isRequired,
  resendLoginVerificationEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  loginConfirmUser,
  resendLoginVerificationEmail,
})(Step);
