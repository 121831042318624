import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  adminChangeUserAccountType,
  adminChangeUserTradingAccountNames,
} from "../../../redux/actions/adminActions";

const ChangeUserAccountType = props => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  const accountTypes = {
    Individual: "Corporate",
    Corporate: "Individual",
  };

  const handleChangeUserAccountType = () => {
    setBtnDisabled(!btnDisabled);

    const postData = {
      changeTo: accountTypes[props.userData.accountType],
      userData: props.userData,
    };

    adminChangeUserAccountType(postData)
      .then(res => {
        if (res.data.success) {
          adminChangeUserTradingAccountNames(props.userData)
            .then(res => {
              if (res.data.success) {
                window.location.reload();
              } else alert("Something went wrong!");
            })
            .catch(err => console.error(err));
        } else alert("Something went wrong!");
      })
      .catch(err => console.error(err));
  };

  return (
    <Modal show={true} onHide={props.toggleModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Change User Account Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to change user account type to{" "}
          {accountTypes[props.userData.accountType]}?
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 justify-content-between">
        <Button
          variant="secondary"
          className="greyButton bg-danger"
          onClick={props.toggleModal}
        >
          NO
        </Button>
        <Button
          type="submit"
          className="darkBlueButton"
          style={{
            fontWeight: "normal",
          }}
          disabled={btnDisabled}
          onClick={handleChangeUserAccountType}
        >
          {btnDisabled ? (
            <>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              YES
            </>
          ) : (
            "YES"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ChangeUserAccountType.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(ChangeUserAccountType);
