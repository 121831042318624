import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Card } from "react-bootstrap";
import classes from "./Profile.module.css";
import {
  getAdmins,
  adminGetAccounts,
  adminCreateSubAccount,
} from "../../../../redux/actions/adminActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import AccountDetails from "../../../../components/Modals/AccountDetails/AccountDetails";
import { NotificationManager } from "react-notifications";

const TradingAccounts = props => {
  const [admins, setAdmins] = useState({});
  const [accounts, setAccounts] = useState({});
  const [state, setState] = useState({
    disableBtn: false,
    showAddAccountModal: false,
    errors: "",
  });

  useEffect(() => {
    triggerAdminGetAccounts();
  }, []);

  const triggerAdminGetAccounts = () => {
    getAdmins()
      .then(res => {
        setAdmins(res.data.admins);
      })
      .catch(err => console.error(err));

    adminGetAccounts(props.userId, "live", "approved")
      .then(res => {
        setAccounts(res.data);
      })
      .catch(err => console.log(err));
  };

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated the account.",
          "Account Update",
          5000
        );
        break;
      case "passwordChanged":
        NotificationManager.success(
          "You have successfully changed the password for this account.",
          "Password Change"
        );
        break;
      case "passwordChangeFailed":
        NotificationManager.error(
          "Something went wrong while changing the password. Please, try again.",
          "Error",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const handleShowModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showAddAccountModal: true,
      };
    });
  };

  const handleCloseModal = () => {
    setState(prevState => {
      return {
        ...prevState,
        showAddAccountModal: false,
        disableEditAccountBtn: false,
      };
    });
  };

  const columnsTradingAccounts = [
    {
      dataField: "number",
      text: "ACCOUNT NUMBER",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "platform",
      text: "PLATFORM",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "managerGroup",
      text: "GROUP",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "baseCurrency",
      text: "BASE CURRENCY",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "leverageText",
      text: "LEVERAGE",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "timeCreatedText",
      text: "DATE OPENED",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "adminId",
      text: "APPROVED BY",
      sort: true,
      classes: "breakWord",
    },
    {
      dataField: "balance",
      text: "BALANCE",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
    {
      dataField: "equity",
      text: "EQUITY",
      sort: true,
      classes: "text-right",
      headerStyle: (col, colIndex) => {
        return { textAlign: "right" };
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        const numA = parseFloat(a);
        const numB = parseFloat(b);
        if (order === "asc") {
          return numB - numA;
        }
        return numA - numB; // desc
      },
    },
  ];

  let dataTradingAccounts = [];
  if (Object.keys(accounts).length > 0) {
    accounts.forEach((keyName, keyIndex) => {
      accounts[keyIndex].timeCreatedText = moment(
        accounts[keyIndex].timeCreated * 1000
      )
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      accounts[keyIndex].leverageText = `1:${accounts[keyIndex].leverage}`;

      if (admins[accounts[keyIndex].adminId]) {
        accounts[keyIndex].adminId = admins[accounts[keyIndex].adminId];
      } else {
        accounts[keyIndex].adminId = "AUTOMATION";
      }

      accounts[keyIndex].balance = parseFloat(
        accounts[keyIndex].balance
      ).toFixed(2);
      accounts[keyIndex].equity = parseFloat(accounts[keyIndex].equity).toFixed(
        2
      );

      dataTradingAccounts.push(accounts[keyIndex]);
    });
  }

  return (
    <>
      <Card className={classes.card}>
        <Card.Body className="p-0">
          <p
            className={[
              "p-3 m-0 border-bottom fontWeight500",
              classes.cardHeader,
            ].join(" ")}
          >
            Trading Accounts
            <Button
              type="button"
              variant="link"
              className="smallGreenButton mt-1 text-white"
              onClick={handleShowModal}
            >
              + ADD
            </Button>
          </p>
          <BootstrapTable
            bootstrap4
            bordered={false}
            keyField="_id"
            data={dataTradingAccounts}
            columns={columnsTradingAccounts}
            hover={true}
            classes={classes.table}
            headerClasses={classes.tableHeaderClass}
          />
        </Card.Body>
      </Card>

      {state.showAddAccountModal ? (
        <AccountDetails
          triggerGetAccounts={triggerAdminGetAccounts}
          handleModalClose={handleCloseModal}
          createNotification={createNotification}
          account={state.selectedAccount}
          userId={props.userId}
        />
      ) : null}
    </>
  );
};

TradingAccounts.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { adminCreateSubAccount })(
  TradingAccounts
);
