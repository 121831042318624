import axios from "axios";
import store from "../../redux/store";
import { GET_ERRORS, GET_SUCCESS, SET_MENU_COUNTERS } from "./types";
import { API_URL } from "../../config/config";

// Get admins
export const getAdmins = () =>
  axios.get(API_URL + "/api/admin/users/getAdmins");

// Get user
export const getUser = id =>
  axios.get(API_URL + "/api/admin/users/profile/" + id);

// Get all users
export const getUsers = (status, limit) =>
  axios.get(API_URL + "/api/admin/users/list/" + status + "/" + limit);

// Get user logs
export const getUserLogs = id =>
  axios.get(API_URL + "/api/admin/users/logs/" + id);

// Get Client Application Data
export const getClientApplicationData = id =>
  axios.get(API_URL + "/api/admin/users/getClientApplicationData/" + id);

// Get Client Application Data
export const getKYCApprovedStatus = id =>
  axios.get(API_URL + "/api/admin/users/getKYCApprovedStatus/" + id);

// Get Client Application Documents
export const getKYCDocuments = id =>
  axios.get(API_URL + "/api/admin/users/getKYCDocuments/" + id);

// Get new requests count
export const getNewRequestsCount = () =>
  axios.get(API_URL + "/api/other/getNewRequestsCount/");

// Check new deposits
export const checkNewDeposits = () =>
  axios.get(API_URL + "/api/other/checkNewDeposits/");

// Set new clients
export const setMenuCounters = counters => {
  return {
    type: SET_MENU_COUNTERS,
    payload: counters,
  };
};

// Add user
export const adminAddUser = (userData, history) => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/register", userData)
    .then(res => history.push("/admin/users"))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit user
export const adminEditUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/editUser", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Change user account type
export const adminChangeUserAccountType = userData =>
  axios.post(API_URL + "/api/admin/users/changeUserAccountType", userData);

// Change user account type
export const adminChangeUserTradingAccountNames = userData =>
  axios.post(API_URL + "/api/admin/accounts/changeTradingAccountNames", {
    userData,
  });

// Process user
export const adminProcessUser = userData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/processUser", userData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Process user
export const adminAddLog = logData => dispatch => {
  axios
    .post(API_URL + "/api/admin/users/addLog", logData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get wallet accounts by userId
export const adminGetWalletAccounts = userId =>
  axios.get(`${API_URL}/api/admin/accounts/adminGetWalletAccounts/${userId}`);

// Get all accounts by userId, type, status
export const adminGetAccounts = (userId, type, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetAccounts/${userId}/${type}/${status}`
  );

// Admin update account
export const adminUpdateAccount = account => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateAccount", account)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin create sub account
export const adminCreateSubAccount = accountData => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminCreateSubAccount", accountData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get bank account details
export const adminGetBankAccount = bankId =>
  axios.get(API_URL + "/api/admin/accounts/adminGetBankAccount/" + bankId);

// Get all pending verification bank details
export const adminGetBankAccounts = (userId, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetBankAccounts/${userId}/${status}`
  );

// Admin update bank account
export const adminUpdateBankAccount = account => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateBankAccount", account)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Check account password change status
export const adminCheckAccountPasswordChangeStatus = accountId => dispatch => {
  return axios
    .post(
      API_URL + "/api/admin/accounts/adminCheckAccountPasswordChangeStatus",
      {
        accountId,
      }
    )
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get all requests by status
export const adminGetFundingRequests = (userId, type, status) =>
  axios.get(
    `${API_URL}/api/admin/accounts/adminGetFundingRequests/${userId}/${type}/${status}`
  );

// Get all ZP pending orders
export const adminGetWithdrawalOrders = () =>
  axios.get(`${API_URL}/api/admin/accounts/adminGetWithdrawalOrders`);

// Admin update deposit
export const adminUpdateDeposit = deposit => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateDeposit", {
      deposit,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin request bank details for a deposit
export const adminDepositReqBankDetails = depositId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminDepositReqBankDetails", {
      depositId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update deposit
export const adminProcessDeposit = depositId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminProcessDeposit", {
      depositId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update transfer
export const adminUpdateTransfer = transfer => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateTransfer", {
      transfer,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update transfer
export const adminProcessTransfer = transferId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminProcessTransfer", {
      transferId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update withdrawal
export const adminUpdateWithdrawal = withdrawal =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateWithdrawal", {
    withdrawal,
  });

// Admin update withdrawal
export const adminProcessWithdrawal = withdrawalId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessWithdrawal", {
    withdrawalId,
  });

// Admin update withdrawal - ZotaPay
export const adminUpdateWithdrawalZotaPay = withdrawal =>
  axios.post(API_URL + "/api/admin/accounts/adminUpdateWithdrawalZotaPay", {
    withdrawal,
  });

// Admin update withdrawal - ZotaPay
export const adminProcessWithdrawalZotaPay = withdrawalId =>
  axios.post(API_URL + "/api/admin/accounts/adminProcessWithdrawalZotaPay", {
    withdrawalId,
  });

// Get all leverage requests
export const adminGetLeverageRequests = status =>
  axios.get(API_URL + "/api/admin/accounts/adminGetLeverageRequests/" + status);

// Admin update leverage request
export const adminUpdateAccountLeverage = account => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateAccountLeverage", {
      account,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update leverage
export const adminProcessLeverageUpdate =
  (changeLeverageId, status) => dispatch => {
    axios
      .post(API_URL + "/api/admin/accounts/adminProcessLeverageUpdate", {
        changeLeverageId,
        status,
      })
      .then(res =>
        dispatch({
          type: GET_SUCCESS,
          payload: res.data,
        })
      )
      .catch(err =>
        dispatch({
          type: GET_ERRORS,
          payload:
            err.response !== undefined
              ? err.response.data
              : { server: "Server error." },
        })
      );
  };

// Admin update close account request
export const adminUpdateCloseAccount = accountId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminUpdateCloseAccount", {
      accountId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Admin update close account request
export const adminProcessCloseAccountUpdate = accountId => dispatch => {
  axios
    .post(API_URL + "/api/admin/accounts/adminProcessCloseAccountUpdate", {
      accountId,
    })
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get transfer history
export const getTransferHistory = () =>
  axios.get(API_URL + "/api/admin/accounts/getTransferHistory");

// Get groups
export const getGroups = () =>
  axios.get(API_URL + "/api/admin/groups/getGroups");

// Add group
export const adminAddGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/addGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Edit group
export const adminEditGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/editGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Delete group
export const adminDeleteGroup = groupData => dispatch => {
  axios
    .post(API_URL + "/api/admin/groups/deleteGroup", groupData)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get all IB Codes
export const adminGetIbCodes = () =>
  axios.get(`${API_URL}/api/admin/users/adminGetIbCodes`);

// Get next IB Code
export const adminGetNextIbCode = () =>
  axios.get(`${API_URL}/api/admin/users/adminGetNextIbCode`);

// Update IB Code
export const adminUpdateIbCode = ibCodeId =>
  axios.post(API_URL + "/api/admin/users/adminUpdateIbCode", ibCodeId);

// Generate Codes
export const adminGenerateCodes = data =>
  axios.post(API_URL + "/api/admin/users/adminGenerateCodes", data);

// Get Generated Codes
export const adminGetGeneratedCodes = agentCode =>
  axios.get(API_URL + "/api/admin/users/adminGetGeneratedCodes/" + agentCode);

// Get Email Notifications
export const adminGetEmailNotifications = userId =>
  axios.get(API_URL + "/api/admin/partners/getEmailNotifications/" + userId);

// Update Email Notifications
export const adminUpdateEmailNotifications = settings => dispatch => {
  axios
    .post(API_URL + "/api/admin/partners/updateEmailNotifications", settings)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Get ML statuses
export const adminGetMlStatuses = () =>
  axios.get(API_URL + "/api/admin/other/getMlStatuses/");

// Clear success/error props
export const clearSuccessErrorProps = () => {
  store.dispatch({
    type: GET_SUCCESS,
    payload: {},
  });
  store.dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
